import Service from "../Service";
import Vue from "vue";

const resource = "AssistementDriverVehicle/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "saveA", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID }
        });
    },

    delete(id, requestID) {
        return Service.post(resource + "delete", id, {
            params: { id, requestID }
        });
    },

    getvehiclebycarrier(dni) {
        return Service.post(resource + "getvehiclebycarrier", dni, {
            params: { dni }
        });
    },

    /* listVehicle(typeCharge, Quantity = 0, requestID) {

        return Service.post(resource + "listVehicle", {}, {
            params: { typeCharge: typeCharge, Quantity: Quantity, requestID: requestID }
        });
    }, */

    listVehicle(typeCharge, Quantity = 0, VehPlate, requestID) {
        return Service.post(resource + "listVehicle", {}, {
            params: { typeCharge: typeCharge, Quantity: Quantity, VehPlate: VehPlate, requestID: requestID }
        });
    },


    GetVehicleConfig() {
        return Vue.prototype.$http.defaults.baseURL + "/AssistementDriverVehicle/GetVehicleConfig";
    },
};