<template scope="props">
	<div>
		<v-container>
			<v-row>
				<v-col cols="12">
					<s-toolbar
						:color="'#898a8c'"
						add
						dark
						@add="AddVehicle()"
						label="Asignación Chofer - Vehículo"
						
					></s-toolbar>

					<v-card>
						<v-col cols="12">
							<v-row>
								<v-col lg="6" cols="12">
									<s-toolbar-person
										add-driver
										autofocus
										label="Chofer"
										return-object
										v-model="ReturPerson"
										@returnPerson="returnPerson($event)"
									></s-toolbar-person>
								</v-col>

								<v-col lg="6">
									<!-- <s-select-transport
										label="Vehículo"
										full
										return-object
										v-model="Objvehiculo"
									/> -->

									<s-select-generic
										label="Vehiculo"
										autocomplete
										itemvalue="VehPlate"
										id="VehID"
										itemtext="VehPlate"
										:config="configVehicle"
										full
										v-model="VehPlate"
									>
									</s-select-generic>
									<!--<SSelectVehicle return-object v-model="Objvehiculo"></SSelectVehicle>-->
								</v-col>
								<v-col lg="12" v-if="Person">
									<s-text disabled v-model="Person.NtpFullName" />
								</v-col>
							</v-row>
							<!--  <v-row>
                <v-col class="s-col-form" cols="12">
                  <template>
                    <v-data-table
                      dense
                      v-model="selectedAsignation"
                      :headers="headers"
                      :items="itemsAsignation"
                      item-key="AdvPlaqueVehicle"
                      show-select
                      class="elevation-1"
                      
                    >
                    </v-data-table>
                  </template>
                </v-col>
              </v-row> -->

							<!-- <v-row justify="center">
                <v-container>
                  <v-row justify="center">
                    <v-col lg="6">
                      <v-btn @click="clearGrilla()" small block
                        ><v-icon x-small left>fas fa-undo-alt</v-icon
                        >Limpiar</v-btn
                      >
                    </v-col>

                    <v-col lg="6">
                      <v-btn small block color="primary " @click="save()">
                        <v-icon small left>fas fa-save</v-icon> Guardar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-row> -->
						</v-col>
					</v-card>
				</v-col>

				<v-col cols="12" style="margin-top: -15px">
					<s-crud
						ref="crud"
						title="Conductor Vehiculo"
						:filter="filter"
						:config="configDriverVehicle"
						@rowSelected="rowSelected($event)"
						no-full
						search-input
					>
					</s-crud>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
	import _AsistementDriverVehicle from "../../../services/Technicalassistance/AssistementDriverVehicle";
	import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
	//import SSelectVehicle from "@/components/Utils/Vehicle/SSelectVehicle";
	/* import SSelectTransport from "../../../components/HarvestSchedule/SSelectTransport.vue";
	 */

	export default {
		components: { SToolbarPerson },
		data: () => ({
			Person: null,
			/* driverName: null, */
			ReturPerson: null,
			VehPlate: null,
			/* Objvehiculo: null, */
			/* vehiculo: null, */
			/* selectedAsignation: [], */
			/* selectChofer: [], */
			/* itemsAsignation: [], */
			/* option: {}, */
			/* numberDocument: "", */
			/* headers: [
				{ text: "DNI", value: "AdvDriverDNI" },
				{ text: "Placa Vehículo", value: "AdvPlaqueVehicle" },
			], */

			configDriverVehicle: {
				model: {
					AdvID: "ID",
					AdvPlaqueVehicle: "string",
					AdvDriverDNI: "string",
					DriverName: "string",
				},
				service: _AsistementDriverVehicle,
				headers: [
					/* { text: "ID", value: "AdvID" }, */
					{ text: "Placa", value: "AdvPlaqueVehicle" },
					{ text: "DNI", value: "AdvDriverDNI" },
					{ text: "Conductor", value: "DriverName" },
					{ text: "Licencia", value: "NpdDocumentNumber" },
				],
			},
		}),
		methods: {
			returnPerson(e) {
				this.Person = e;
			},

			/* Initialize() {
				this.filter = {};
			}, */

			AddVehicle() {
				//console.log('tttiii', this.Objvehiculo);
				// asiganr
				if (this.VehPlate == null) {
					this.$fun.alert("Seleccione un vehiculo", "warning");
					return;
				}

				if (this.Person !== null) {
					let obj = {
						AdvPlaqueVehicle: this.VehPlate,
						AdvDriverDNI: this.Person.PrsDocumentNumber,
						SecStatus: 1,
						UsrCreateID: this.$fun.getUserID(),
					};

					console.log("Guardar", obj);

					this.$fun.alert("Seguro de guardar", "question").then(r => {
						if (r.value) {
							_AsistementDriverVehicle
								.save(obj, this.$fun.getUserID())
								.then(resp => {
									if (resp.status == 200) {
										this.$fun.alert(
											"Guardado Corectamente",
											"success",
										);
										this.$refs.crud.refresh();
									}
								});
						}
					});
				} else {
					this.$fun.alert("Seleccione una Persona", "warning");
					return;
				}

				/* this.itemsAsignation.push(obj);
				this.clearOption(); */
			},

			/* removeMaterial() {
				this.selectedAsignation.forEach(element => {
					this.itemsAsignation = this.itemsAsignation.filter(
						x => x.Placa != element.Placa,
					);
				});
			}, */

			/* 	clearOption() {
				this.Objvehiculo = null;
				this.ReturPerson = null;
				this.Person = null;
			},
			clearGrilla() {
				this.itemsAsignation = [];
			}, */

			//este metodo se ejecutara cuando se presione el boton guardar
			save() {
				//estas son las validaciones
				if (this.itemsAsignation == null) {
					this.$fun.alert("Grilla Vacía", "Warning");
					return;
				}
				for (let i = 0; i < this.itemsAsignation.length; i++) {
					if (this.itemsAsignation[i].AdvDriverDNI == null) {
						this.$fun.alert(
							"Falta Placa del Vehículo," +
								this.selectedAsignation[i].AdvDriverDNI,
							"Warning",
						);
						return;
					}
				}
				//aqui despues de las validaciones deberias llamar al servicio y enviar la lista que quieres guardar
				this.$fun.alert("Seguro de guardar", "question").then(r => {
					if (r.value) {
						_AsistementDriverVehicle
							.save(this.itemsAsignation, this.$fun.getUserID())
							.then(resp => {
								this.$fun.alert("Guardado Corectamente", "success");
								this.$refs.crud.refresh();
								this.itemsAsignation = [];
							});
					}
				});
			},

			rowSelected(item) {
				console.log("itemmm", item);
				if (item.length > 0) {
					this.driverName = item[0].DriverName;
				}
			},
		},

		created() {
			this.filter = {};

			this.configVehicle = {
				url: _AsistementDriverVehicle.GetVehicleConfig(),
				title: this.label,
				params: { search: "", requestID: this.$fun.getUserID(), AdvID: 0 },
			};
		},
	};
</script>
